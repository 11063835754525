export default {
    Nav: {
        Home: '官网首页',
        About: '关于我们',
        Game: '游戏产品',
        Joinus: '加入我们',
        contactUs: '联系我们',
        secret:'隐私协议',
        terms:'服务条款',
    },
    Footer: {
        cont: "Copyright {'@'} 2023 All Right Reserved 鑫盛網絡有限公司 版权所有",
    },
    commencont:{
        Coming: '敬请期待'
    },
    homecont: {

    },
    contactus: {
        address:'中国香港九龙尖沙咀柯士甸路7-9號煥利商業大厦7樓63室',
        title:'鑫盛網絡有限公司'
    },
    Aboutus:{
        about1: '鑫盛網絡有限公司成立于2022年7月，总部位于中国香港，专注游戏研发、游戏跨国发行的综合性互动娱乐企业。',
        about2: '公司拥有业界顶级的研发、营运团队、以游戏产业的根基出发，布局全球，凭借雄厚的技术实力和产品创意设计能力，多款SLG、MMORPG精品游戏同步研发中，我们将凭借着优秀的团队服务拓广至全球。',
        about3: '鑫盛網絡将秉持专业化服务和匠心精神，为全球用户提供更多精品游戏产品，超越用户的期望，为全球各玩家提供优质体验和服务。',
    },
    title:{
        title: '鑫盛網絡 - 全球手机游戏发行与研发商',
        Keywords: '鑫盛網絡,手机游戏,網絡游戏,mobile game,安卓游戏,苹果游戏,ios游戏,android游戏,网络游戏,发行商,海外游戏,香港游戏,谷歌商店,苹果商店,下载,安装,免费游戏.',
        Description:'一家手机游戏研发与发行商。'
    },
    Secret:{
        src:'/static/policy.html'
    },
    Terms:{
        src:'/static/terms.html'
    }
}
