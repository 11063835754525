<template>
  <el-affix :offset="0">
    <Nav />
  </el-affix>
  <div class="w1900">
    <router-view></router-view>
    <Footer />
  </div>
  <!--  <img alt="Vue logo" src="./assets/logo.png">-->
</template>

<script>
import Nav from "./components/Nav.vue";
import Footer from "./components/footer.vue";
export default {
  name: "App",
  components: {
    Nav,
    Footer,
  },
  setup() {
    let url = window.location.href;
    if (url.indexOf("auto") > -1) {
      sessionStorage.setItem("active", 2);
    } else if (url.indexOf("Gamelist") > -1) {
      sessionStorage.setItem("active", 3);
    } else if (url.indexOf("ContactUs") > -1) {
      sessionStorage.setItem("active", 5);
    }
    // 判断是否是手机端
    const isMobile = () => {
      const flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    };
    if (isMobile()) {
      let url = window.location.href;
      let arr = url.split("/#/");
      if (arr.length > 1) {
        // window.location = `https://www.xinsheng.fun/m/#/${arr[1]}`;
        // window.open(`https://www.xinsheng.fun/m/#/${arr[1]}`);
      } else {
        // window.location = "https://www.xinsheng.fun/m/#/";
        // window.open("https://www.xinsheng.fun/m/#/");
      }
    }
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
* {
  margin: 0;
  padding: 0;
}
</style>
