export default {
    Nav: {
        Home: 'Home',
        About: 'About',
        Game: 'Game',
        Joinus: 'Join Us',
        contactUs: 'Contact Us',
        secret:'Policy',
        terms:'Terms'
    },
    Footer: {
        cont: "© 2023 Xinsheng Network Co., Ltd. All Rights Reserved."
    },
    commencont:{
        Coming: 'Coming soon'
    },
    contactus: {
        address:'ROOM 63,7/F,WOON LEE COMMERCIAL BUILDING,7-9AUSTIN AVE',
        title:'Xinsheng Network Co., Ltd.'
    },
    Aboutus:{
        about1: 'Founded in July, 2022, Xinsheng Network Co., Ltd. is headquartered in Hong Kong, China. It is a comprehensive interactive game developer and publisher.',
        about2: 'Our company has devised vairous SLG and MMORPG games with industry-leading R&D and operation teams. We will strive to expand our business to the world with our advanced technology, creative ideas and quality services.',
        about3: 'We will stay commited to offering professional services and improving our products. Beyond your expectations is our ultimate goal.',
    },
    title:{
        title: 'Xin Sheng Fun Games',
        Keywords: 'xin sheng games,mobile games,netgame,android,apple game,ios game,publisher,overseas game,hong kong games,google play games,appstore,download,install,Samsung,huawei,oppo,vivo,meta,vr game, war game,strategy game, revenge, war strategy, free games, online games, mobile app, arabic game,RTS, MMO,world game',
        Description:'A mobile game develop and publisher。'
    },
    Secret:{
        src:'/static/policy-en.html'
    },
    Terms:{
        src:'/static/terms-en.html'
    }
}
